import "./Home.css";

const Home = () => {
  return (
    <>
      <img src={"./me.jpg"} className="me-pic" id="me" alt="pic not found" />
      <h2>Helloo, I'm a backend software engineer based out of NYC </h2>
      <h2>
        My simple pleasures in life are pho, the dogs that look like carpets, karaoke,
        and word hunt
      </h2>
    </>
  );
};

export default Home;
